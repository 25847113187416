.article-content {
    max-width: 50%;
    min-width: 300px;
    margin: 100px auto;
    align-items: center;
}

.article-content h2 {
    text-align: center;
    font-size: 45px;
    margin-bottom: 70px;
}
.article-content p {
    font-size: 25px;
    line-height: 1.5;
    text-align: justify;
}
.image{
    display: flex;
    justify-content: center;
    width: 100%;
    align-content: center;
    margin-top: 30px;
}
.containerImgArticle{
    margin-top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.blogTitle {
    margin-top: 150px;
}

.paraBlog{
    padding-top: 20px;
    margin-top: 10px;
}
.imageArticle{
    height: 100%;
    width: 450px;
    

}