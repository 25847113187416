.home-title {
  font-size: 60px;
  margin: 100px 0 40px;
  text-align: center;
  font-family: "Times New Roman", Times, Baskerville, Georgia, serif;
}

.titleAndBtnDeleteArticle {
  display: flex;
  justify-content: space-between;
  font-family: Aref Ruqaa, "sans-sérif";
  
}

.container-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0;
  padding-bottom: 100px;
}

.deleteBtn {
  position: relative;
  min-width: 20px;
  max-height: 20px;
  background: red;
  color: #f1f1f1;
  border-radius: 5px;
  font-weight: 600;
}

.test {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.cardParaArticle {
  height: auto;
  overflow: hidden;
  /* ellipse  une ligne */
  /* white-space: nowrap;
  text-overflow: ellipsis;  */
  
  /* ellipse a partir de la deuxième ou troisième ligne */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
 
}




.linkCardToArticle {
  padding: 20px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}


.linkExt{
  display: flex;
  justify-content: space-between;
}

img{
 width: 40px;
}