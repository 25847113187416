.title-form {
    text-align: center;
    font-size: 55px;
    margin: 90px 0 0;
}
.error{
    color: red;
    text-align: center;
    margin-top: 30px;
    font-size: 40px;
   
}

.container-form{
    padding: 10px;
    margin: 30px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.container-form label {
    font-size: 20px;
    text-align: center;
}

.container-form input, .container-form textarea {
    padding: 10px;
    margin-bottom: 25px;
    font-size: 18px;
    width: 40%;
    min-width: 300px;
}
.container-form textarea {
    min-height: 200px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.container-form button {
    padding: 10px 15px;
    width: 200px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    cursor: pointer;
}

.img-center{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.previsualisation-image{
    width: 300px;
    height: 300px;
}

.img-none{
    text-align: center;
    
}