.container-contact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  /* margin: 100px auto 0; */
  font-size: 25px;
  text-align: center;
}
.container-contact h1 {
  font-size: 50px;
  margin-bottom: 10px;
}
.container-contact p:nth-child(4) {
  margin-top: 50px;
  padding: 10px;
}
.lien-contact {
  text-decoration: none;
  margin-right: 10px;
}

p{
    padding: 5px;
}

li {
  list-style-type: none;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a{
    text-decoration: none;
}
