@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Lora:ital,wght@0,400;0,700;1,700&family=Marck+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shizuru&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&family=Lemonada:wght@300;400;500;700&family=Macondo&display=swap");

.card {
  max-width: 300px;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin: 10px;
  cursor: pointer;

  /* Une fois le photos mise remettre la min-heigt a 400 */
  min-height: 400px;
  max-height: 400px;
  min-width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card h2 {
  
  /* ellipse  une ligne */
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */

  /* ellipse a partir de la deuxième ou troisième ligne */
 
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-size: 20px;
  font-family: Aref Ruqaa, sans-serif ;
  margin-bottom: 10px;
  margin-top: 10px;

  
}
.para{
  padding: 10px;
}
.card p {
  font-size: 18px;
 font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
