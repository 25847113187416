nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background: #00a991;
  z-index: 1;
}

.liste {
  list-style-type: none;
  background: #00a991;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 22px;
}
.items {
  width: 100%;
  text-align: center;
  margin: 0 15px;
  text-transform: uppercase;
  color: #f1f1f1;
  cursor: pointer;
}



.items a {
  text-decoration: none;
  color: #f1f1f1;
}

/* pour le connecté en tant que : en mode innactif pour le moment  */
/* .items.Start {
  display: flex;
  text-align: right;
  flex-direction: row;
  left: 10px;
  text-decoration: none;
  color: #f1f1f1;
  text-transform: capitalize;
  cursor:default;
} */

.itemsEnd {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 10px;
}
.itemsEnd a {
  text-decoration: none;
  color: #f1f1f1;
  text-transform: uppercase;
}

.btn {
  display: none;
  position: absolute;
  width: 50px;
  right: 10px;
  top: 10px;
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .liste {
    flex-direction: column;
    height: auto;
  }
  .items:nth-child(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    margin-top: 0px;
  }
  .items {
    height: 70px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    line-height: 70px;
    text-align: center;
  }
  .items:nth-child(1){
    border-top: none;
  }
  .btn {
    display: block;
  }
 
}
