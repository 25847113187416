.logContent{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
}
.titleLog {
  text-align: center;
  margin-bottom: 30px;
  font-size: 25px;
}
.containerBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textToLog {
  padding: 10px;
}


.signForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signTitle {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.signInp {
  border-radius: 10px;
  height: 50px;
  width: 200px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.signBtn {
  height: 40px;
  outline: none;
  border: none;
  height: 50px;
  width: 180px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
  font-weight: bold;
} 
.logForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logTitle {
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .buttonCenter {
    display: flex;
    justify-content: center;
  }
  .logMail,
  .logPass {
    border-radius: 10px;
    height: 50px;
    width: 200px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .logSubmit {
    height: 40px;
    outline: none;
    border: none;
    height: 50px;
    width: 180px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 30px;
    font-weight: bold;
  } 

  .btnLog {
    height: 40px;
    outline: none;
    border: none;
    height: 50px;
    width: 110px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
  }
  